import { EmbedCard, ResponseHandler } from '../../Shared/components';
import { FetchState } from '../../Shared/interfaces';
import { useFetch } from '../../Shared/hooks/useFetch';
import { DASHBOARD_DOMO_CRED_URL } from '../../Shared/constants/routes';
import { Box } from '@mui/material';


export const EmbedDashboard = (props: {data: any}) => {
	const {data} = props;

	const request: FetchState = useFetch(`${DASHBOARD_DOMO_CRED_URL}`, true, null, {id: data.id}, true);

	return (
		<ResponseHandler request={request}>
			<Box sx={{height: '90%'}}>
				{
					request.success &&
					!request.loading &&
					<EmbedCard
						embedUrl={request.data.embedUrl}
						embedToken={request.data.embedToken}
						resolution={{width: 1200, height: 1800}}
					/>
				}
			</Box>
		</ResponseHandler>
	);
}