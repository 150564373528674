import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DASHBOARD_LIST_FOR_USER, DASHBOARD_ROOT_URL } from '../../Shared/constants/routes';
import { useFetch } from '../../Shared/hooks/useFetch';

interface DashboardSelectList {
	id: string;
	name: string;
}

const SelectDashboard = (props: { currentDashboardId: string; sx?: any }): React.ReactElement => {
	const navigate = useNavigate();
	const { currentDashboardId, sx } = props;

	const dashboards = useFetch<DashboardSelectList[]>(DASHBOARD_LIST_FOR_USER);

	const onSelect = (event: SelectChangeEvent) => {
		const id = event.target.value;
		navigate(`${DASHBOARD_ROOT_URL}/${id}`);
	};

	return (
		<>
			{dashboards.success ? (
				<Select
					sx={{
						height: '50px',
						minWidth: '150px',
						borderRadius: '50px',
						...sx,
					}}
					onChange={onSelect}
					value={currentDashboardId}
				>
					{dashboards.data.map((dashboard, index) => {
						return (
							<MenuItem
								selected={currentDashboardId === dashboard.id}
								key={`dashboard-${index}`}
								value={dashboard.id}
							>
								{dashboard.name}
							</MenuItem>
						);
					})}
				</Select>
			) : (
				<Select
					sx={{ height: '50px', minWidth: '150px', marginRight: '20px', borderRadius: '50px', ...sx }}
					onChange={onSelect}
					value={''}
				></Select>
			)}
		</>
	);
};

export default SelectDashboard;
