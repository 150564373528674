import { useState, useEffect } from 'react';
import { LOG_CHECK_CONNECTION_URL } from '../constants/routes';

const { REACT_APP_API_URL } = process.env;

const useNetworkStatus = () => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		const updateOnlineStatus = () => {
			setIsOnline(navigator.onLine);
		};

		window.addEventListener('online', updateOnlineStatus);
		window.addEventListener('offline', updateOnlineStatus);

		return () => {
			window.removeEventListener('online', updateOnlineStatus);
			window.removeEventListener('offline', updateOnlineStatus);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (navigator.onLine) {
				fetch(`${REACT_APP_API_URL}${LOG_CHECK_CONNECTION_URL}`)
					.then(() => {
						setIsOnline(true);
					})
					.catch(() => {
						setIsOnline(false);
					});
			}
		}, 70000);

		// Cleanup on unmount
		return () => {
			clearInterval(interval);
		};
	}, []);

	return isOnline;
};

export default useNetworkStatus;