import React, { useState } from 'react';
import { useSubmit } from '../../../Shared/hooks/useSubmit';
import { BUG_REPORT_URL } from '../../../Shared/constants/routes';
import { Button, DialogActions, FormControl, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import validationSchema from './validation';


type BugReportObject = {[key: string]: any};

const BugReportForm = (props: { handleClose: () => void }) => {
	const { handleClose } = props;

	const [description, setDescription] = useState<string>('');
	const [stepsToReproduce, setStepsToReproduce] = useState<string>('');
	const [expectedOutcome, setExpectedOutcome] = useState<string>('');
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const { enqueueSnackbar } = useSnackbar();

	const bugReportObject: BugReportObject = {
		description: description,
		stepsToReproduce: stepsToReproduce,
		expectedOutcome: expectedOutcome,
		browserInfo: getBrowserInfo(),
	};

	const turnToFormData = (obj: BugReportObject): FormData => {
		const formData = new FormData();
		if (selectedFile) {
			formData.append('file', selectedFile);
		}
		for(const name in obj) {
			formData.append(name, obj[name]);
		}

		return formData;
	}

	const { callAPI, resetState } = useSubmit(BUG_REPORT_URL, turnToFormData(bugReportObject), true);

	const handleSubmit = () => {
		validationSchema.validate({
			description,
			stepsToReproduce,
		}).then(() => {
			callAPI();
			resetState();
			enqueueSnackbar('Report Sent', { variant: 'success' });
			handleClose();
		}).catch((error: any) => {
			enqueueSnackbar(error.message, { variant: 'error' });
		})
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			const file = event.target.files[0];
			const validFileExtensions = ['image/png', 'image/jpeg'];
			if (!validFileExtensions.includes(file.type)) {
				enqueueSnackbar('Invalid file type. Only .png and .jpg/.jpeg are allowed', { variant: 'error' });
				return;
			}
			if (file.size / 1024 / 1024 > 5) { // size more than 5MB
				enqueueSnackbar('File size too large. Must be 5MB or less', { variant: 'error' });
				return;
			}
			setSelectedFile(file);
		}
	}

	return (
		<FormControl>
			<TextField
				onChange={(event) => setDescription(event.target.value)}
				autoFocus
				margin='dense'
				label='Brief Description of the Bug'
				type='text'
				fullWidth
				required
			/>
			<TextField
				onChange={(event) => setStepsToReproduce(event.target.value)}
				margin='dense'
				label='Steps to Reproduce'
				type='text'
				fullWidth
				multiline
				required
			/>
			<TextField
				onChange={(event) => setExpectedOutcome(event.target.value)}
				margin='dense'
				label='Expected Outcome'
				type='text'
				fullWidth
				multiline
			/>

			<TextField
				type='file'
				onChange={handleFileChange}
				margin='dense'
				fullWidth
				helperText='Supported file types: .png, .jpg, .jpeg. Max file size: 5MB.'
			/>

			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					Cancel
				</Button>
				<Button type='submit' color='primary' onClick={handleSubmit}>
					Submit
				</Button>
			</DialogActions>
		</FormControl>
	);
};

export default BugReportForm;

const getBrowserInfo = () => {
	const info =  {
		userAgent: navigator.userAgent,
		screenSize: {
			width: window.screen.width,
			height: window.screen.height,
			colorDepth: window.screen.colorDepth,
		},
		currentUrl: window.location.href,
		cookiesEnabled: navigator.cookieEnabled,
		jsVersion: typeof Symbol === 'function' ? 'ES6' : 'Pre-ES6',
	};

	return JSON.stringify(info);
};
