//Node Modules
import React, { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';

//Internal
import { ImpersonatedDashboard } from '../modules/Dashboards/components/ImpersonatedDashboard';
import { IsAuthorized, LoggedOut, Login } from '../modules/Core/components';
import { NODE_TYPES } from '../modules/Shared/constants/NodeTypes';
import ManageEntities from '../modules/ManageEntities';
import ManageUsers from '../modules/ManageUsers';
import ManageCards from '../modules/ManageCards';
import {
	CARD_DETAILS_URL,
	CARD_LIST_URL,
	ENTITIES_ROOT_URL,
	ENTITY_DETAILS_URL,
	IMPERSONATED_DASHBOARDS_URL,
	IMPERSONATED_DASHBOARD_URL,
	USERS_ROOT_URL,
	USER_DETAILS_URL,
	NEW_DASHBOARD_URL,
	NODE_DETAILS_URL,
	DASHBOARD_MANAGEMENT_LIST,
	DASHBOARD_MANAGEMENT_DETAILS,
	DASHBOARD_OPEN,
} from '../modules/Shared/constants/routes';
import Dashboard2 from '../modules/Dashboards';
import { CardDetails } from '../modules/ManageCards/components';
import { UserDetails } from '../modules/ManageUsers/components';
import { NodesDetails } from '../modules/ManageNodes/components';
import { EntityDetails } from '../modules/ManageEntities/components/EntityDetails';
import ManageNodes from '../modules/ManageNodes';
import ManageDashboards from '../modules/ManageDashboards';
import AdminPanel from '../modules/AdminPanel/AdminPanel';
import Settings from '../modules/AdminPanel/Settings';
import { DashboardDetails } from '../modules/ManageDashboards/components/DashboardDetails';

export const AppRoutes = (): ReactElement => {
	return (
		<Routes>
			<Route path="/loggedOut" element={<LoggedOut />}></Route>
			<Route path="/login" element={<Login />}></Route>
			<Route path="/admin" element={<IsAuthorized adminOnly={true} />}>
				<Route path={'/admin'} element={<AdminPanel />} />
				<Route path={'/admin/card-settings'} element={<Settings />} />
				<Route path={'/admin/nodes'} element={<ManageNodes />} />
			</Route>
			<Route path="/" element={<IsAuthorized />}>
				<Route path={USER_DETAILS_URL} element={<UserDetails nodeType={NODE_TYPES.User} />} />
				<Route path={ENTITY_DETAILS_URL} element={<EntityDetails nodeType={NODE_TYPES.Entity} />} />
				<Route path={CARD_DETAILS_URL} element={<CardDetails nodeType={NODE_TYPES.Card} />} />
				<Route path={NODE_DETAILS_URL} element={<NodesDetails nodeType={NODE_TYPES.Node} />} />
				<Route path={ENTITIES_ROOT_URL} element={<ManageEntities />} />
				<Route path={USERS_ROOT_URL} element={<ManageUsers />} />
				<Route path={CARD_LIST_URL} element={<ManageCards />} />
				<Route path={IMPERSONATED_DASHBOARD_URL} element={<ImpersonatedDashboard />} />
				<Route path={IMPERSONATED_DASHBOARDS_URL} element={<ImpersonatedDashboard />} />
				<Route path={DASHBOARD_MANAGEMENT_LIST} element={<ManageDashboards />} />
				<Route path={DASHBOARD_OPEN} element={<Dashboard2 />} />
				<Route path={DASHBOARD_MANAGEMENT_DETAILS} element={<DashboardDetails nodeType={NODE_TYPES.Dashboard} />} />
				<Route index element={<Dashboard2 />} />
				<Route path={NEW_DASHBOARD_URL} element={<Dashboard2 />} />
			</Route>
		</Routes>
	);
};
