import * as React from 'react';
import { DetailsTable } from '../../../Shared/components/DetailsTable';
import { EditUserEntityRelationships } from '../EditUserEntityRelationships';
import { EditRelationsActionsBox } from '../../../Shared/components';
import { useColumnsWidth } from '../../../Shared/hooks/useColumnsWidth';

export const EntityTable = (props: { entities: any, user: any, reRender: Function }) => {
	const { entities, user, reRender } = props;
	const dateFormatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'US/Arizona' });

	const { columnsWidth } = useColumnsWidth(4);
	const columns = [
		{ field: 'name', headerName: 'Name', minWidth: columnsWidth },
		{ field: 'id', headerName: 'Id', minWidth: columnsWidth },
		{
			field: 'createdOn',
			headerName: 'Created',
			minWidth: columnsWidth,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
		{
			field: 'modifiedOn',
			headerName: 'Modified',
			minWidth: columnsWidth,
			align: 'left',
			valueFormatter: (params: any) => {
				const date = new Date(params?.value);
				return dateFormatter.format(date);
			},
		},
	];

	const rows = entities.map((entity: any) => {
		const entityRow = {
			key: entity.id,
			more: entity.id,
			...entity,
		};

		return entityRow;
	});

	return <>
		<EditRelationsActionsBox>
			<EditUserEntityRelationships user={user} reRenderComponent={reRender} />
		</EditRelationsActionsBox>
		<DetailsTable rows={rows} columns={columns} />
	</>
};
