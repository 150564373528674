import React, { useCallback, useState } from 'react';
import DOMODatasetSelector from './DOMOQuerysetSelector';
import { FormControl, FormControlLabel, FormLabel, Switch, TextField, Typography } from '@mui/material';
import DOMOColumnsSelector from './DOMOColumnsSelector';

interface SelectColumnNameProps {
	handleSelect: (value: string) => void;
}

const SelectColumnName = (props: SelectColumnNameProps): React.ReactElement => {
	const { handleSelect } = props;
	const [datasetId, setDatasetId] = useState<string | null>(null);
	const [manual, setManual] = useState<boolean>(false);
	const [disableSelector, setDisableSelector] = useState<boolean>(false);

	const handleDSChange = (value: string) => {
		setDatasetId(value)
	}

	const onChange = (value: string) => {
		handleSelect(value);
	}

	const onSwitch = useCallback(() => {
		setDatasetId(null);
		setManual(!manual);
	}, [setDatasetId, setManual, manual])

	const failedReqCallback = useCallback(() => {
		onSwitch();
		setDisableSelector(true);
	}, [onSwitch, setDisableSelector])

	const autoSelectByQS = <div>
		<DOMODatasetSelector onChange={handleDSChange} />
		{datasetId ? <DOMOColumnsSelector onSelect={onChange} qsId={datasetId} failedReqCb={failedReqCallback} /> : null}
	</div>

	return (
		<FormControl fullWidth={true}>
			<FormLabel>Identifier Field</FormLabel>
			{!disableSelector ? <FormControlLabel
				control={<Switch onChange={onSwitch} />}
				label='Manual'
				style={{ whiteSpace: 'normal', overflow: 'hidden', textOverflow: 'ellipsis' }}
			/> : null}
			{
				manual ?
					<TextField
						autoFocus
						onChange={(event) => handleSelect(event.target.value)}
						placeholder={'Field name'}
					/>
				:
					!disableSelector ? <div>
						{autoSelectByQS}
					</div> : null
			}
			{disableSelector ? <Typography sx={{margin: '10px'}}>Unable to fetch columns. Please enter the field name manually</Typography> : null}
		</FormControl>
	)
}

export default SelectColumnName;