import React, { ReactElement } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Drawer as MuiDrawer, Box, ListItem, ListItemText, Button, Theme } from '@mui/material';
import { MUIStyledCommonProps } from '@mui/system/createStyled';
import { styled, useTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import * as portals from 'react-reverse-portal';

interface RouteItemType {
	name: string;
	route: string;
}

interface SettingsRouteItemProps {
	theme: Theme;
	routeItem: RouteItemType;
	locationPath: string;
}

interface StyledListItemProps extends MUIStyledCommonProps {
	active: boolean;
	theme: Theme;
}

const DrawerWrapper = styled('div')(({ theme }) => ({
	'& .MuiDrawer-root': {
		width: '250px',
	},
	'& .MuiPaper-root': {
		width: '250px',
		background: theme.palette.primary.main,
		border: 'none',
		justifyContent: 'space-between',
		'&::after': {
			content: '""',
			background: theme.palette.secondary.main,
			position: 'absolute',
			bottom: 0,
			right: 0,
			height: '100%',
			width: '5px',
		},
	},
}));

const StyledLink = styled(Link)(() => ({
	paddingLeft: '25px',
	textDecoration: 'none',
	color: 'inherit',
}));

const StyledListItem = styled(ListItem)(({ theme, active }: StyledListItemProps) => ({
	padding: '4px 0px',
	paddingLeft: '16px',
	background: active ? theme.palette.tertiary.main : theme.palette.primary.main,
	color: active ? green[700] : theme.palette.primary.contrastText,
	borderRadius: '25px 0px 0px 25px',
	border: active ? `5px solid ${theme.palette.secondary.main}` : `5px solid ${theme.palette.primary.main}`,
	borderRight: 'none',
	'&::after': {
		content: '""',
		background: active ? theme.palette.tertiary.main : theme.palette.secondary.main,
		position: 'absolute',
		bottom: 0,
		right: 0,
		height: '100%',
		width: '5px',
		zIndex: 100,
	},
}));

const settingsRoutes = [
	{
		name: 'Card Setting',
		route: '/admin/card-settings',
	},
	{
		name: 'Manage Nodes',
		route: `/admin/nodes`,
	},
];

const AdminPanel = (_props: any): ReactElement => {
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();

	const portalNode = React.useMemo(() => portals.createHtmlPortalNode(), []);

	return (
		<DrawerWrapper>
			<MuiDrawer variant='permanent' PaperProps={{}}>
				<Box>
					<Button sx={{ width: '100%' }} variant='contained' color='success' onClick={() => navigate('/')}>
						Home
					</Button>
					{settingsRoutes.map((routeItem) => (
						<SettingsRouteItem theme={theme} routeItem={routeItem} locationPath={location.pathname} />
					))}
				</Box>
			</MuiDrawer>
			<Box sx={{marginLeft: "250px"}}>
				<Outlet context={{ portalNode }} />
			</Box>
		</DrawerWrapper>
	);
};

const SettingsRouteItem: React.FC<SettingsRouteItemProps> = ({ theme, routeItem, locationPath }) => (
	<StyledLink to={routeItem.route}>
		<Box>
			<StyledListItem theme={theme} active={locationPath === routeItem.route}>
				<ListItemText sx={{ color: 'inherit' }}>{routeItem.name}</ListItemText>
			</StyledListItem>
		</Box>
	</StyledLink>
);

export default AdminPanel;
