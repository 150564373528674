import React, { useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { DASHBOARD_IMPERSONATED, DASHBOARD_ROOT_URL } from '../Shared/constants/routes';
import { useFetch } from '../Shared/hooks/useFetch';
import { ResponseHandler } from '../Shared/components';
import DashboardCanvas from './components/DashboardCanvas';
import { Box } from '@mui/material';
import { DashboardBar } from './components/DashboardBar';
import { useSelector } from 'react-redux';
import { UserState } from '../../store/userReducer';
import { StoreState } from '../../store/store';
import ErrorBoundary from '../Core/components/ErrorBoundary/ErrorBoundary';
import { EmbedDashboard } from './components/EmbedDashboard';

const Dashboard2 = (): any => {
	const params = useParams<{ id?: string }>();
	const location = useLocation() as any;
	const state = location.state;
	const userState: UserState = useSelector((state: StoreState) => state.user);

	const [reRender, setReRender] = useState<boolean>(false);

	const reRenderComponent = (): void => {
		setReRender(!reRender);
	};

	const fetchURL = state?.impersonatedUserId ? DASHBOARD_IMPERSONATED : `${DASHBOARD_ROOT_URL}/${params.id ?? ''}`;
	const payload = state?.impersonatedUserId ? { impersonatedUserId: state?.impersonatedUserId } : null;

	const dashboardResponse = useFetch<any>(fetchURL, true, [reRender], payload, !!payload);

	const getDashboardComponent = (data: any) => {
		if (data.type === 'embedType') {
			return <EmbedDashboard data={data} />
		} else {
			return <DashboardCanvas
				cardInstances={data?.cardInstances}
				impersonatedUserId={data?.impersonatedUserId}
				impersonation={data?.impersonation}
				dashboardId={data?.id}
				allowEdit={data?.allowEdit}
				reRenderComponent={reRenderComponent}
				defaultDashboardId={data?.defaultDashboardId}
			/>
		}
	}

	return (
		<ErrorBoundary>
			<ResponseHandler request={dashboardResponse}>
				<Box sx={{ width: '100%', position: 'relative' }}>
					<DashboardBar
						name={dashboardResponse.data?.name}
						currentDashboardId={dashboardResponse.data?.id}
						provisioned={userState.provisioned}
					/>
					{dashboardResponse.data && getDashboardComponent(dashboardResponse.data)}
				</Box>
			</ResponseHandler>
		</ErrorBoundary>
	);
};

export default Dashboard2;
