//Node Modules
import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';
import * as portals from 'react-reverse-portal';

//Material UI
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

//Internal
import { Drawer } from './Drawer';
import { useWindowHeight } from '../../Shared/hooks/useWindowHeight';
import { useWindowWidth } from '../../Shared/hooks/useWindowWidth';
import { Loading } from '../../Shared/components';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../store/store';
import { userSlice } from '../../../store/userReducer';
import { logoutConfig } from '../../../utils/auth0Utils';
import { ROLES } from '../../Shared/constants/roles';
import { UnauthorizedAccessPage } from './UnauthorizedAccessPage';
import AdminPanel from '../../AdminPanel/AdminPanel';
import MobileMenuButton from './MobileMenuButton';

const { REACT_APP_REDIRECT, REACT_APP_SUPPORT_EMAIL } = process.env;

export const IsAuthorized = (props: { adminOnly?: boolean }): ReactElement => {
	const { adminOnly } = props;

	const [openDrawer, setOpenDrawer] = useState<boolean>(false);

	const { logout, loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { height } = useWindowHeight(0);
	const { width } = useWindowWidth(0);
	const userState = useSelector((state: StoreState) => state.user);

	const standardScreen = useMediaQuery(theme.breakpoints.up('md'));

	const portalNode = React.useMemo(() => portals.createHtmlPortalNode(), []);

	const handleLogout = () => {
		logout(logoutConfig).then();
	};

	const signOut = (): void => {
		dispatch(userSlice.actions.logout());
		handleLogout();
	};

	if (!isLoading && !isAuthenticated) {
		loginWithRedirect({
			authorizationParams: {
				redirect_uri: `${REACT_APP_REDIRECT}/login`,
			},
		}).then();
	}

	if (!isLoading && isAuthenticated && !userState.id) {
		handleLogout();
	}

	if (!isLoading && isAuthenticated && adminOnly && userState.role < ROLES.ADMIN) {
		return <UnauthorizedAccessPage />;
	}

	if (!isLoading && isAuthenticated && adminOnly && userState.role === ROLES.ADMIN) {
		return <AdminPanel />;
	}

	const setClose = () => {
		setOpenDrawer(false);
	};

	return !isLoading && isAuthenticated && userState.id !== '' ? (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: standardScreen ? 'row' : 'column',
					height: height,
					width: width,
					background: '#ffffff',
				}}
			>
				<MobileMenuButton open={openDrawer} setOpen={setOpenDrawer} />
				{standardScreen ? (
					<Drawer portalNode={portalNode} />
				) : openDrawer ? (
					<Drawer portalNode={portalNode} setClose={setClose} />
				) : null}
				<Outlet context={{ portalNode }} />
				{!userState.provisioned && (
					<Modal open={true} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
						<Dialog open={true}>
							<DialogTitle>Account Not Provisioned</DialogTitle>
							<DialogContent>
								<Typography>
									Your Automatit user account has not been provisioned for use. Please
									<a href={`mailto:${REACT_APP_SUPPORT_EMAIL}`}> contact support</a> to get your account setup.
								</Typography>
							</DialogContent>
							<DialogActions>
								<Button onClick={signOut}>Logout</Button>
							</DialogActions>
						</Dialog>
					</Modal>
				)}
			</Box>
		</>
	) : (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: window.innerWidth,
				height: window.innerHeight,
				background: '#264a5d',
			}}
		>
			<Loading color="info" />
		</Box>
	);
};
