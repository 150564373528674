//Node Modules
import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { NavigationItems } from './NavigationItems';
import { useSelector } from 'react-redux';

const automatitRoutes = [
	{
		name: 'Manage Cards',
		route: '/cards',
	},
	{
		name: 'Manage Users',
		route: '/users',
	},
	{
		name: 'Manage Dashboards',
		route: '/dashboards/list',
	},
];

//This exists as callback URL for AUth0 and redirects to root
export const AdminSection = (): ReactElement => {
	const user = useSelector((state: any) => state.user);

	return (
		<Box sx={{ direction: 'ltr', paddingTop: '16px' }}>
			<Typography
				variant="h6"
				sx={{
					fontWeight: '500',
					fontSize: '12px',
					color: '#8C8C8C',
					padding: '0 0 0 24px',
					fontFamily: 'Public-Sans, sans-serif',
					marginBottom: '12px',
				}}
			>
				Administration
			</Typography>
			<NavigationItems routes={user.role > 2 ? [...automatitRoutes] : automatitRoutes} />
		</Box>
	);
};
