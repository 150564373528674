import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { MaxCardSizeSettings } from './MaxCardSizeSettings';
import { DefaultCardSizeSettings } from './DefaultCardSizeSettings';
import { InactivityTimerSettings } from './InactivityTimerSettings';

const Settings = (): ReactElement => {
	return (
			<Box>
				<DefaultCardSizeSettings />
				<MaxCardSizeSettings />
				<InactivityTimerSettings />
			</Box>
	)
}

export default Settings;
