import React, { useEffect } from 'react';
import Selector from './Selector';
import { useFetch } from '../../hooks/useFetch';
import { CARD_DOMO_COLUMNS } from '../../constants/routes';
import { Loading } from '../Loading';

interface DOMOColumnsSelectorProps {
	qsId: string;
	onSelect: (value: string) => void;
	failedReqCb: () => void;
}

const DOMOColumnsSelector = (props: DOMOColumnsSelectorProps): React.ReactElement => {
	const {qsId, onSelect, failedReqCb} = props;
	// Get columns from API;
	const response = useFetch(CARD_DOMO_COLUMNS, true, [qsId], {datasetId: qsId}, true)

	useEffect(() => {
		if (!response.loading && !response.success) {
			failedReqCb();
		}
	}, [response.loading, response.success, response.error, failedReqCb] );

	const handleChange = (value: string) => {
		onSelect(value);
	}

	return (!response.loading && response.success ? <Selector onChange={handleChange} options={response.data || []} /> : <Loading />)
}

export default DOMOColumnsSelector;