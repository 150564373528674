import { BackendLogger } from './BackendLogger';

interface RetDet {
	message: string;
}

interface ResponseWithRetDet {
	RetDet: RetDet;
}

interface ILogger {
	logError: (error: Error, logType?: string) => void;
}

type ISnackbar = (message: string, variant: 'default' | 'error' | 'success' | 'warning' | 'info') => void;


const isRetDet = (data: any): data is ResponseWithRetDet => {
	return !!data && 'RetDet' in data;
}

export class ErrorHandler {
	readonly snackbar: ISnackbar;
	readonly logout: () => void;
	readonly displayFallbackComponent: (message?: string) => void;

	private defaultErrorMessage = 'An unknown error occurred';

	constructor(logout: () => Promise<void>, snackbar: ISnackbar, displayFallbackComponent: (message?: string) => void, private logger: ILogger = new BackendLogger()) {
		this.logout = logout;
		this.snackbar = snackbar;
		this.displayFallbackComponent = displayFallbackComponent;
	}

	async handle(error: Response | Error) {
		if (error instanceof Response) {
			const data = await error.json();
			const message = this.getErrorMessage(data);
			switch (error.status) {
				case 400:
					this.snackbar(`Invalid Request: ${message}`, 'warning');
					this.logger.logError(new Error(`Bad request: ${message}`), 'info');
					break;
				case 401:
					this.snackbar('Unauthorized: Please sign in again', 'error');
					this.logout();
					break;

				case 403:
					this.displayFallbackComponent('Forbidden: You do not have permission to access this');
					break;

				case 404:
					this.displayFallbackComponent('Not Found: The requested page is not available');
					break;

				case 422:
					this.snackbar(`Error: ${message}`, 'error');
					break;

				case 429:
					// Too Many Requests;
					break;

				case 500:
					this.logger.logError(new Error(message || 'Internal Server Error'));
					this.displayFallbackComponent(message ? `Server Error: ${message}` : 'Internal Server Error');
					break;

				default:
					this.logger.logError(new Error(message || 'An unknown error occurred'));
					break;
			}
		} else {
			// Network error or permission issues
			console.error(error);
			if (error.name === 'TimeoutError') {
				console.error("Timeout: It took more than 5 seconds to get the result!");
				this.logger.logError(error);
				this.snackbar('Request Timeout: The server took too long to process your request.' +
					' Please try again later', 'error');
				return;
			} else if (error.name === "AbortError") {
				console.error( "Fetch aborted by user action");
			} else {
				this.logger.logError(error);
				this.snackbar('Network Error: Please try again later', 'error');
			}
		}
	}

	private getErrorMessage(data: any): string {
		let message: string;
		if (isRetDet(data)) {
			message = data.RetDet.message;
		} else {
			message = data.message || this.defaultErrorMessage;
		}
		return message;
	}
}
