import React, { useState } from 'react';
import BugReportForm from './BugReportForm';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';


const BugReportButton = (): React.ReactElement => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button
				variant='contained'
				color='success'
				onClick={handleClickOpen}
				sx={{
					position: 'fixed',
					bottom: '0',
					left: 0,
					right: 0,
					height: '23px',
					width: '250px',
					borderRadius: '11px 11px 0px 0px;',
					padding: '0',
					'&:hover': {
						backgroundColor: 'darkgreen',
					},
				}}
			>
				<BugReportIcon />
			</Button>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Report a Bug</DialogTitle>
				<DialogContent>
					<BugReportForm handleClose={handleClose} />
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default BugReportButton;
