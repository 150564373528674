//Node Modules
import React, { useState } from 'react';

//Material UI
import { Box, Typography } from '@mui/material';
import { formatDate } from '../../../../utils/dateFormatter';

//Internal
import { DataField } from '../../../Shared/components/NodeDetailsFields/DataField';
import EditableField from '../../../Shared/components/NodeDetailsFields/EditableField';
import { NodeFull } from '../../../Shared/interfaces';
import { useSubmit } from '../../../Shared/hooks/useSubmit';
import { DASHBOARD_EDIT_URL } from '../../../Shared/constants/routes';
import { EditDashboardIsPublic } from './EditDashboardIsPublic';
import { stringValidation } from '../../../ManageUsers/constants/validationSchemas';

export const DashboardData = (props: { data: any, updateTitleCallback: Function }) => {
	const { data, updateTitleCallback } = props;

	const [name, setName] = useState<string>(data.name || '');
	const [isPublic, setIsPublic] = useState<boolean>(data.isPublic || false);

	const dashboardData: Partial<NodeFull> = {
		id: data.id,
		name: name,
		isPublic: isPublic,
	};

	const { callAPI, resetState } = useSubmit<NodeFull>(DASHBOARD_EDIT_URL, dashboardData);

	const handleSubmitEdit = () => {
		callAPI();
		resetState();
		updateTitleCallback(name);
	}

	const onPublicChange = (value: string) => {
		setIsPublic(value === '1')
	}

	return (
		<Box
			id="infoSection"
			sx={{
				padding: '2% 2%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-around',
				minHeight: '100%',
			}}
		>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Basic Info
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<EditableField
						label={'Dashboard Name'}
						value={dashboardData.name || ""}
						onChange={setName}
						onSubmit={handleSubmitEdit}
						validationSchema={stringValidation}
					/>
					<DataField label={'Id'} value={data.id} />
					<EditableField
						label={'Public'}
						value={dashboardData.isPublic ? '1' : '2'}
						onChange={onPublicChange}
						onSubmit={handleSubmitEdit}
						customEditComponent={EditDashboardIsPublic}
						displayValue={dashboardData.isPublic ? 'Public' : 'Private'}
					/>
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
					margin: '10px 0',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>
					Timestamps
				</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Created On'} value={formatDate(data.createdOn)} />
					<DataField label={'Created By'} value={data.createdBy} />
					<DataField label={'Modified On'} value={formatDate(data.modifiedOn)} />
					<DataField label={'Modified By'} value={data.modifiedBy} />
				</Box>
			</Box>
			<Box
				sx={{
					padding: '20px 20px 0px 20px',
					borderRadius: '20px',
					border: '1px solid #264a5d50',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginBottom: '20px', fontWeight: 700, fontSize: '20px', color: '#264a5d' }}>Usage</Typography>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<DataField label={'Users'} value={data.users?.length ?? 0} />
					<DataField label={'Instances'} value={data.instances?.length ?? 0} />
				</Box>
			</Box>
		</Box>
	);
};
